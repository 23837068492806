import React from 'react';
import { VStack, Text, Image, Center, Stack } from '@chakra-ui/react';

export default function PeopleAdvantageCard({
  icon,
  title,
  description,
  cardProps = null,
  iconProps = null,
}) {
  return (
    <Stack
      borderRadius={{ desktop: '48px', tablet: '40px', mobile: '24px' }}
      w="full"
      h={{ desktop: '480px', laptop: '400px', mobile: '340px' }}
      gap="0"
      bgColor="bg.white"
      spacing="48px"
      justify="space-between"
      {...cardProps}
    >
      <Center
        boxSize={{ laptop: '72px', mobile: '40px' }}
        bgColor="bg.white"
        m="12px"
        borderRadius="50%"
        flexShrink="0"
        {...iconProps}
      >
        <Image src={icon} alt="icon" boxSize={{ laptop: '32px', mobile: '24px' }} />
      </Center>
      <VStack
        w="full"
        align="start"
        spacing="20px"
        p={{ laptop: '48px', tablet: '22.5px', mobile: '24px' }}
        pt="0 !important"
      >
        <Text as="h3">{title}</Text>
        <Text textStyle="h1" fontWeight="500">
          {description}
        </Text>
      </VStack>
    </Stack>
  );
}
