import React from 'react';

import WhyUs from 'widgets/WhyUs';
import Reviews from 'widgets/Reviews';
import Projects from 'widgets/Projects';
import Greetings from 'widgets/Greetings';
import ContactUs from 'widgets/ContactUs';
import Companies from 'widgets/Companies';
import TechExpert from 'widgets/TechExpert';
import Specialists from 'widgets/Specialists';
import Cooperation from 'widgets/Cooperation';
import PageMetaData from 'widgets/PageMetaData';
import ExpertiseCardsSlider from 'widgets/ExpertiseCardsSlider';
import BlockHeader from 'shared/BlockHeader';
import Wrapper from 'shared/Wrapper';

import { cooperations } from 'constants/cooperations';
import { projectsData } from 'constants/bodyshop/projects';
import { dedicatedTeamGreetingData } from 'constants/dedicatedTeam';
import { dedicatedTeamExpertiseCardsSliderData } from 'constants/expertiseCardsSlider';

export default function DedicatedTeamPage() {
  return (
    <>
      <PageMetaData
        title="Выделенные команды разработки — ASTON"
        description="Сформированные команды ИТ-специалистов всех направлений для вашего проекта. Всего 10 дней для начала работы."
        img={{
          src: 'https://storage.yandexcloud.net/dev.astonsite.s3backet/astondevs/metaPreviews/dedicated-team.png',
          alt: 'Превью для страницы Выделенная команда',
        }}
      />
      <Wrapper pt={{ desktop: '170px', laptop: '173px', mobile: '120px' }}>
        <Greetings
          titleSx={{
            maxW: { laptop: '940px', tablet: '700px', mobile: '400px' },
            color: 'text.black',
          }}
          btnSx={{
            textStyle: 'button.tertiary',
            fontSize: { laptop: '24px', tablet: '20px', mobile: '14px' },
            lineHeight: { tablet: '120%', mobile: '100%' },
          }}
          {...dedicatedTeamGreetingData}
        />
        <ExpertiseCardsSlider {...dedicatedTeamExpertiseCardsSliderData} />
        <TechExpert />
        <Companies />
        <Specialists />
        <WhyUs />
        {/* @ts-ignore */}
        <Projects
          HeaderRender={<BlockHeader>Реализованные проекты</BlockHeader>}
          {...projectsData}
        />
        <Reviews />
        <Cooperation
          HeaderRender={
            <BlockHeader>
              Выберите подходящую <br /> модель сотрудничества
            </BlockHeader>
          }
          cardsData={cooperations.filter(data => data.title !== 'Выделенная команда')}
          showButton={false}
        />
        <ContactUs
          description="Заполните форму, и мы свяжемся с вами и расскажем, как компания ASTON поможет расти вашему бизнесу"
          descriptionProps={{ maxW: { desktop: '700px', laptop: '585px', tablet: '460px' } }}
        />
      </Wrapper>
    </>
  );
}
