import { Button, Grid } from '@chakra-ui/react';
import { whyUsData } from 'constants/dedicatedTeam';
import React, { useState } from 'react';
import BlockHeader from 'shared/BlockHeader';
import BlockWrapper from 'shared/BlockWrapper';
import Slider from 'shared/Slider';
import { goToForm } from 'shared/lib/goToForm';
import PeopleAdvantageCard from '~entities/PeopleAdvantageCard';

export default function WhyUs() {
  const [swiper, setSwiper] = useState();

  return (
    <BlockWrapper spacing="24px">
      <BlockHeader mb={{ mobile: '8px', laptop: '24px' }}>Почему мы</BlockHeader>
      <Grid templateColumns="1fr 1fr" gap="20px" hideBelow="tablet" justifyItems="center">
        {whyUsData.map((data, i) => (
          <PeopleAdvantageCard key={i} {...data} />
        ))}
      </Grid>
      <Slider
        mousewheel={false}
        controller={swiper}
        setController={setSwiper}
        slidesData={whyUsData}
        SlideComponent={PeopleAdvantageCard}
        container={{
          hideFrom: 'tablet',
        }}
      />
      <Button w="full" size="secondary" onClick={goToForm}>
        Оставить заявку
      </Button>
    </BlockWrapper>
  );
}
